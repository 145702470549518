<template>
  <div class="card">
    <div class="card-header justify-content-between d-flex align-items-center">
      <h4 class="card-title">
        Abonos de la factura {{ factura.ns }}
      </h4>
      <div>
        <a href="#" onclick="return false;" @click="cerrar()">
          <i class="mdi mdi-close text-dark"></i>
        </a>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-nowrap">
          <thead>
            <tr>
              <td></td>
              <td><strong>Fecha</strong></td>
              <td><strong>Hora</strong></td>
              <td class="text-right"><strong>Cargos</strong></td>
              <td class="text-right"><strong>Abonos</strong></td>
              <td class="text-left"><strong>Usuario registrador</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Factura
                <router-link
                  :to="{ name: 'factura', params: { id: factura.id } }"
                  v-if="factura.id != null"
                >
                  {{ factura.ns }}
                </router-link>
              </td>
              <td>{{ formatoFecha(factura.fecha) }}</td>
              <td></td>
              <td class="text-right">
                {{ hrr.fmon(factura.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </td>
              <td></td>
              <td></td>
            </tr>
            <tr v-for="abono in abonos" :key="abono.id">
              <td>Abono {{ abono.id }}</td>
              <td>{{ abono.fecha }}</td>
              <td>{{ formatoHora(abono.hora) }}</td>
              <td></td>
              <td class="text-right">
                {{ hrr.fmon(abono.importe_asignado, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </td>
              <td class="text-left">
                <span v-show="abono.generada_por_sistema">
                  Generada por sistema
                </span>
                <span v-show="!abono.generada_por_sistema">
                  {{ abono.nombre_usuario}}
                  {{ abono.id_usuario == null ? 'Mi servicio de internet' : ''}}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="3">Totales</td>
              <td
                class="text-right"
                :class="{ 'text-warning': totalAbonos > 0 }"
              >
                {{ hrr.fmon(factura.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </td>
              <td
                class="text-right"
                :class="{
                  'text-info': totalAbonos > 0,
                  'text-danger': totalAbonos == 0
                }"
              >
                {{ hrr.fmon(totalAbonos, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
              </td>
              <td></td>
            </tr>
            <tr>
              <td colspan="3">
                <strong>Saldo de la factura</strong>
              </td>
              <td class="text-right">
                <strong>{{ hrr.fmon(factura.saldo, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}</strong>
              </td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import FacturaSrv from '@/services/FacturaSrv.js'
import moment from 'moment'
export default {
  name: 'CmpAbonosFactura',
  props: ['idFactura'],
  data() {
    return {
      hrr: iu.hrr,
      factura: {
        id: null,
        cliente: { id: null }
      },
      abonos: [],
      totalAbonos: 0
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    var self = this

    // Cargas iniciales
    this.cargarFactura()
    this.refrescarAbonos()
  },

  methods: {
    atras: function() {
      this.$router.go(-1)
    },
    cerrar: function() {
      this.atras()
    },
    cargarFactura: function() {
      var self = this

      FacturaSrv.facturaJSON(self.idFactura).then(response => {
        self.factura = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },
    formatoFecha(mt){
      return moment(mt, 'YYYY-MM-DD').format('DD/MM/YYYY')
    },
    formatoHora(hrs) {
      return moment(hrs, 'HH:mm:ss').format('LT')
    },
    refrescarAbonos: function() {
      var self = this

      FacturaSrv.abonosFacturaJSON(self.idFactura).then(response => {
        self.abonos = response.data

        self.abonos.forEach(function(abono) {
          self.totalAbonos += abono.importe_asignado
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }
  },
}
</script>